<template>
  <el-dialog :visible.sync="dialogFormVisible" :show-close="false" width="600px" @opened="loading">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>设置角色</span>
      </div>
    </div>
    <el-form ref="form" :model="form" :rules="ruleForm" label-position="top" style="text-align: left">
      <el-form-item label="角色" prop="roles">
        <el-select v-model="form.roles" placeholder="请选择角色" style="width: 100%">
          <el-option v-for="item in rolesList" :key="item.alias" :label="item.name" :value="item.alias"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="submit('form')">确 定</el-button>
      <el-button @click="closeDialog('form')">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { phoneReg } from "../lib/global";
import { api } from '../api';
// export const userAddCustomerUser = api()("user.add.customerUser.json");
// 查询企业简单列表
export const modifyUserRoles = api()('modifyUserRoles.json');
export const roleList = api()('role.list.json');

export default {
  props: {
    dialogFormVisible: Boolean,
    setCode: String,
    setRoles: String
  },
  data () {

    return {
      enterpriseList: [],
      form: {
        roles: '',
      },
      ruleForm: {
        roles: [
          { required: true, message: '请输入选择角色', trigger: 'change' }
        ],
      },
      rolesList: []
    };
  },
  watch: {
    setCode: async function (val, old) {
      if (val === old) return;
      console.log(val, old, '================');
      await this.loading()
    }
  },
  async created () {

    await this.getroleList()
  },
  methods: {
    loading () {

      if (this.setRoles === 'r2') {
        this.form.roles = '企业管理员'
      }
      else {
        this.form.roles = this.setRoles
      }

    },
    async getroleList () {

      this.form.roles = this.setRoles
      const res = await roleList({
        pageNumber: 1,
        pageSize: 10000,
        type: ''
      });
      if (window.localStorage.getItem('roles') != 'r0' || window.localStorage.getItem('roles') != 'r1') {
        const data = res.list.filter(it => it.code != 'r1' && it.code != 'r0' && it.code != 'r2' && it.code != 'r4' && it.code != 'r7')
        this.rolesList = data

      }
      else {
        this.rolesList = res
      }


    },
    closeDialog () {
      this.$emit('update:dialogFormVisible', false);
      this.$emit('close');
    },
    submit (form) {
      this.$refs[form].validate(async isValid => {
        if (isValid) {

          await modifyUserRoles({
            roles: this.form.roles,
            code: this.setCode
          });
          // this.$message.success("创建成功！");
          this.closeDialog();
        }
      });
    }
  }
};
</script>
